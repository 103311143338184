<template>
  <div class="detail">
    <div class="container" ref="scrollbox">
      <el-breadcrumb
        class="defauult-breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item :to="{ path: '/product' }"
          >产品中心</el-breadcrumb-item
        >
        <el-breadcrumb-item>产品详情</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <breadcrumb class="defauult-breadcrumb" :list="breadcrumb_list"></breadcrumb> -->
      <div class="centre_list">
        <!-- 产品购买 -->
        <div class="order">
          <!--产品图片 -->
          <div class="picture">
            <div class="picture-div"><img :src="$utils.picturePrefix(banner)" alt="" /></div>
            <div class="chart">
              <template v-for="(item, i) in bannerList">
                <img
                  v-if="i != bannerIndex"
                  :key="i"
                  :src="$utils.picturePrefix(item)"
                  alt=""
                  @click="bannerDealWith(i)"
                />
              </template>
            </div>
          </div>
          <!--产品详情 -->
          <div class="type">
            <div class="tag" style="font-size: 24px;color: #333333;">
              {{ list.name }}({{ list.typeName }})
            </div>
            <div style="color: #f3254e;">
              <span style="font-size: 24px;">&#65509;</span>
              <span style="font-size: 30px;">{{ list.price }}</span>
            </div>
            <div class="amount">
              <span class="num num-1">数量</span>
              <el-input-number
                v-model="number"
                :min="1"
                :max="999"
                :precision="0"
                label="请输入数量"
              ></el-input-number>
              <span class="num">件</span>
            </div>
            <el-button
              @click="go_productDetails"
              class="payment"
              type="danger"
              style=" margin: 160px 0px 0px 10px;background-color: #f3254e;"
              >立即支付</el-button
            >
          </div>
        </div>
      </div>
      <!-- 产品介绍 -->
      <!-- <div class="introduce">
        {{ list.detail }}
      </div> -->
      <!-- 产品说明 -->
      <div class="explain-title">
        产品介绍
      </div>
      <div class="explain" v-html="list.detail">
        <!-- {{list.detail}} -->
        <!-- <p>品牌： 苏泊尔（SUPOR）</p>
        商品名称：苏泊尔DR2H1商品编号：100012477128商品毛重：11.6kg商品产地：中国大陆类型：纯水机出水速度：1-2L/分钟适用场景：厨房 -->
      </div>
      <!-- <div class="poster">
        <div>
          <img
            style="width: 750px; height: 900px;"
            :src="$utils.picturePrefix(list.banner)"
            alt=""
          />
        </div>
        <div>
          <img
            style="width: 790px; height: 700px;"
            :src="$utils.picturePrefix(list.banner)"
            alt=""
          />
        </div>
        <div>
          <img
            style="width: 790px; height: 1355px;"
            :src="$utils.picturePrefix(list.banner)"
            alt=""
          />
        </div>
        <div>
          <img
            style="width: 790px; height: 995px;"
            :src="$utils.picturePrefix(list.banner)"
            alt=""
          />
        </div>
      </div> -->
    </div>
    <div v-show="btnFlag" class="back-top" id="back" @click="backTop">
        <img src="../../../assets/img/common/backTop.png" alt="">
        <p>返回顶部</p>
    </div>
    
  </div>
</template>

<script>
// import Breadcrumb from "@/components/Breadcrumb";
export default {
  // components: { Breadcrumb },
  data() {
    return {
      breadcrumb_list: [
        {
          path: "/product",
          name: "产品中心"
        },
        {
          path: "",
          name: "产品详情"
        }
      ],
      banner: "",
      bannerList: [],
      bannerIndex: 0,
      list: {},
      num: 1,
      number: 1,
      btnFlag: true,
    };
  },
  computed: {
    isPower() {
      return this.$store.getters.isPower;
    },
    getUser() {
      return this.$store.getters.user;
    }
  },
  watch: {},
  methods: {
    // 点击支付，跳转到购买页
    go_productDetails() {
      if (this.number > 0) {
        if (this.isPower) {
          if (this.getUser && this.getUser.isPartner) {
            this.visible = true;
            this.$router.push(
              `/product/detail/payment?id=${this.$route.query.id}&num=${this.number}&price=${this.list.price}`
            );
          } else {
            this.$message.error("您还不是城市合伙人，请联系管理员");
          }
        } else {
          this.$message.error("请登录");
          this.$router.replace("/login");
        }
      } else {
        this.$message.error("购买数量不正确！");
      }
    },
    //  产品详情
    query_getlist() {
      this.$api.getProductDetail(this.$route.query.id).then(res => {
        let { code, data } = res;
        if (code == 200) {
          data.banner = data.banner.split(",");
          this.list = data;
          this.bannerDealWith(0);
        }
      });
    },
    /**
     * banner图片处理
     */
    bannerDealWith(i) {
      let banner = [...this.list.banner];
      this.banner = banner[i];
      this.bannerList = banner;
      this.bannerIndex = i;
    },
    add_number() {
      if (this.number >= 99) {
        this.number = 99;
      } else {
        this.number++;
      }
    },
    delet_number() {
      if (this.number <= 1) {
        this.number = 1;
      } else {
        this.number--;
      }
    },
    listenerFunction(){
      document.addEventListener('scroll', this.handleScroll, true);
    },
    beforeDestroy() {
      document.removeEventListener('scroll', this.listenerFunction);
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop () {
      let app = document.getElementById('app');
      app.scrollTo(0, 0);
        // this.$refs.scrollbox.scrollTop = 0;
    },
  
    
  },
  created() {
    this.query_getlist();
  },
  mounted() {
    this.listenerFunction();
  },
  beforeDestroy() {
    this.beforeDestroy();
  },
  destroyed () {
    
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>
